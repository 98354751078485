import create from 'zustand';

const useEshop = create((set) => ({
    settings: {
        mainUrl: 'https://mojavec.com/',
        version: "v0.012",
        deployDate: "13.10.2024",
        theme: 'light',
        language: 'sk',
        currency: 'EUR',
        deliveryFreeFrom:35, //if 0,no free delivery
        deliveryOptions: [
            { id: 0, name: 'Slovenská pošta, 1.trieda', cost: 2.90 },
            { id: 1, name: 'GLS kuriér', cost: 3.90 },
            { id: 2, name: 'Osobný odber', cost: 0 }
            // Add more delivery options as needed
        ],
        paymentOptions: [
            { id: 0, name: 'Bankový prevod, platba vopred', cost: 0,visible_in_delivery:[0,1,2] },
            { id: 1, name: 'Dobierka', cost: 1,visible_in_delivery:[0,1] },
            { id: 2, name: 'Platba v predajni', cost: 1,visible_in_delivery:[2] }

            ],
        maleSizes: [ "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"],
        femaleSizes: ["XS", "S", "M", "L", "XL","2XL","3XL"],
        allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
        allSizes: {
            "143": "110",
            "144": "122",
            "145": "134",
            "146": "146",
            "147": "158",
            "160": "2XL",
            "161": "3XL",
            "162": "4XL",
            "163": "5XL",
            "215": "Detská",
            "158": "L",
            "157": "M",
            "149": "S",
            "216": "Uni",
            "159": "XL",
            "148": "XS",
            // sice tag velkosti aj ked je to strih ale plati to pre siltovky
            "174": "UNI",
            "173": "Detská",
            "172": "Chlapec",
            "154": "Dievča",

            "153": "Ženská",
            "152": "Mužská"
        }
    },
    setSettings: (newSettings) => set((state) => ({ settings: { ...state.settings, ...newSettings } }))
}));

export default useEshop;